import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import Img from "gatsby-image"
import Blobs from "../components/blobs"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./projects.css"

const ProjectPage = ({ pageContext }) => (
  <Layout>
    <SEO title={pageContext.title}></SEO>
    <div className="projectMain">
      <Img className="mainImage" fluid={pageContext.mainImage}></Img>
      <h2>{pageContext.title}</h2>
      <BlockContent 
        blocks={pageContext.body}
        imageOptions={{w: 640, h: 640, fit: 'max'}}
        projectId="5re0s6iv"
        dataset="prod"
      ></BlockContent>
    </div>
    <div class="paginationContainer">
      {
        pageContext.previous
        ? <Link className="previousLink" to={pageContext.previous.slug.current}>&lt;&lt; Previous</Link>
        : null
      }
      <a className="backLink" href="/" onClick={(e) => {
        e.preventDefault()
        window.history.back()
      }}
      >Back</a>
      {
        pageContext.next
        ? <Link className="nextLink" to={pageContext.next.slug.current}>Next &gt;&gt;</Link>
        : null
      }
    </div>
    <Blobs className="projectBlob"></Blobs>
  </Layout>
)

export default ProjectPage
